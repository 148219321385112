<template>
    <div class="404">
        wodiu
    </div>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style scoped>

</style>
