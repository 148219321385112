// @ts-ignore
import request from '@/utils/request';

export async function Login(params) {
    return request({
        url: '/auth/login',
        method: 'POST',
        params: params,
    });
}

export async function getInfo() {
    return request({
        url: '/auth/info',
        method: 'GET',
    });
}

export async function getQrcode() {
    return request({
        url: '/anon/qrcode',
        method: 'GET',
    });
}

export async function postQrcodeLogin(state) {
    return request({
        url: '/anon/qrcode-login/' + state,
        method: 'POST',
    });
}

export async function forgetCode(phone) {
    return request({
        url: '/anon/forget-pwd/'+ phone +'/verify-code',
        method: 'GET',
    });
}

export async function submitCode(state) {
    return request({
        url: '/anon/forget-pwd/'+ state.account +'/verify-code/' + state.code,
        method: 'POST',
    });
}

export async function forgetPassword(state) {
    return request({
        url: '/anon/forget-pwd/'+ state.account +'/verify-code/'+ state.code +'/pwd/'+ state.password,
        method: 'POST',
    });
}
