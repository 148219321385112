<template>
    <div class="activity">
        <div class="activity-main">
            <div class="activity-title">
                <div class="text">线下活动</div>
            </div>
            <a-spin :spinning="spinning">
                <div class="activity-status">
                    <div class="activity-status-left">
                        <div
                                class="activity-status-item"
                                :class="{ active: activeShow === 1 }"
                                @click="basculerActive(1)"
                        >未开始</div
                        >
                        <div
                                class="activity-status-item"
                                :class="{ active: activeShow === 2 }"
                                @click="basculerActive(2)"
                        >进行中</div
                        >
                        <div
                                class="activity-status-item"
                                :class="{ active: activeShow === 3 }"
                                @click="basculerActive(3)"
                        >已结束</div
                        >
                    </div>
                    <div class="activity-status-right">
                        <a-input-search
                                v-model="paramsList.name"
                                placeholder="活动名称"
                                style="height: 40px; width: 100%"
                                @search="onSearch"
                        />
                    </div>
                </div>
                <div class="activity-content">
                    <div class="activity-content-main">
                        <div v-for="(item, index) in activityList.data" :key="index" @click="routerLink(item)">
                            <div class="activity-content-item">
                                <div class="activity-content-time">{{
                                    useTime(item.duration)
                                    }}</div>
                                <img :src="cdnImgUrl + item.coverPage" alt="" />
                                <div class="item-text">
                                    <div class="title">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a-pagination
                            style="margin-top: 20px"
                            v-model="currentValue"
                            :page-size="paramsList.size"
                            :page-size-options="pagination.pageSizeOptions"
                            :total="pagination.total"
                            show-size-changer
                            show-quick-jumper
                            @change="onChange"
                            @showSizeChange="onShowSizeChange"
                            :show-total="(total) => `共 ${total} 条数据`"
                    />
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import { useTime } from '@/utils/hook';
    import { getActivities } from '@/api/activity';
    export default {
        data() {
           return {
               spinning: false,
               tagList: {
                   data: []
               },
               activityList: {
                   data: []
               },
               tagActive: 0,
               activeShow: 1,
               paramsList : {
                   categoryId: '',
                   page: 0,
                   size: 10,
                   sort: '',
                   name: '',
                   status: 1,
               },
               currentValue: 1,
               pagination: {
                   pageSizeOptions: ['10', '20', '30'],
                   total: 0,
               },
               infoAllList: JSON.parse(localStorage.getItem('infoAllList')),
               cdnImgUrl: 'https://cdn-img.q-media.org.cn/',
               imgUrl: ['jpg', 'png', 'jpeg', 'bmp', 'wmf', 'mpeg'],
               useTime: useTime,
           }
        },
        created() {
            this.getActivity();
        },
        methods: {
            getActivity() {
                getActivities(this.paramsList).then((res) => {
                    this.activityList.data = res.data.list;
                    this.pagination.total = res.data.total;
                    this.spinning = false;
                });
            },
            basculerActive(num) {
                this.paramsList.status = num;
                this.activeShow = num;
                this.getActivity();
            },
            onSearch() {
                this.getActivity();
            },
            onChange(page, pageSize) {
                this.paramsList.page = page - 1;
                this.getActivity();
            },
            onShowSizeChange(current, pageSize) {
                this.paramsList.size = pageSize;
                this.paramsList.page = 0;
                this.getActivity();
            },
            routerLink(item) {
                this.$router.push({ path: '/activity/details', query: { value: JSON.stringify(item)}})
            }
        },
        mounted() {}
    }
</script>

<style scoped lang="less">
    .activity-main {
        padding: 24px;
        .activity-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: black;
            }
        }
        .activity-status {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            .activity-status-left {
                display: flex;
                .activity-status-item {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 25px;
                    color: rgba(0, 0, 0, 0.6);
                    margin-left: 18px;
                    cursor: pointer;
                    &:hover {
                        background: #e51d1a;
                        color: white;
                    }
                }
                .active {
                    background: #e51d1a;
                    color: white;
                }
            }
            .activity-status-right {
                width: 22%;
                margin-right: 5%;
            }
        }
        .activity-content-main {
            margin-top: 25px;
            margin-bottom: 25px;
            margin-left: 1%;
            .activity-content-item {
                width: 19%;
                cursor: pointer;
                float: left;
                margin-right: 1%;
                position: relative;
                .activity-content-time {
                    position: absolute;
                    right: 0;
                    bottom: 67px;
                    padding: 6px;
                    background-color: rgba(0, 0, 0, 0.4);
                    color: white;
                }
                img {
                    width: 100%;
                    height: 140px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .item-text {
                    background-color: white;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    .title {
                        height: 67px;
                        font-size: 18px;
                        padding-top: 8px;
                        padding-left: 8px;
                        padding-bottom: 3px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: black;
                        text-align: center;
                    }
                }
            }
            &::after {
                content: '';
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
        }
    }
</style>
