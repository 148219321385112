<template>
    <div class="home-box">
        <div class="home-main">
            <div class="home-main-title">智慧党建云课堂</div>
            <div class="home-main-item">
                <div class="home-main-menu">
                    <router-link to="/study/study">
                        <div class="home-icon">
                            <img src="../../assets/icon-study.png" alt="" />
                        </div>
                        <div class="home-text">课程学习</div>
                    </router-link>
                </div>
                <div class="home-main-menu">
                    <router-link to="/activity/activity">
                        <div class="home-icon">
                            <img src="../../assets/icon-activity.png" alt="" />
                        </div>
                        <div class="home-text">线下活动</div>
                    </router-link>
                </div>
                <div class="home-main-menu">
                    <router-link to="/branch/branch">
                        <div class="home-icon">
                            <img src="../../assets/icon-branch.png" alt="" />
                        </div>
                        <div class="home-text">支部风采</div>
                    </router-link>
                </div>
            </div>
            <div class="home-main-logout">
                <a-button type="primary" class="logout-btn" block @click="onLogout">
                    退出
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { removeToken } from '@/utils/localToken';
    export default {
        name: "home",
        data () {
            return {
            }
        },
        created() {
        },
        methods: {
            onLogout() {
                localStorage.removeItem('infoAllList');
                removeToken();
                this.$router.replace('/login');
            }
        }
    }
</script>

<style lang="less">
    .home-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('../../assets/bg.png') no-repeat;
        background-size: 100%;
        .home-main {
            width: 100vw;
            height: 100vh;
            background: rgba(242, 234, 234, 0.4);
            backdrop-filter: blur(10px);
            .home-main-title {
                font-size: 68px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                color: #e51d1a;
                margin-top: 30px;
            }
            .home-main-item {
                display: flex;
                justify-content: space-evenly;
                margin-top: 35px;
                .home-main-menu {
                    width: 25%;
                    padding: 30px;
                    background-color: white;
                    border-radius: 16px;
                    backdrop-filter: blur(10px);
                    border: 2px solid #ffffff;
                    .home-text {
                        font-size: 52px;
                        font-weight: 600;
                    }
                }
                .home-main-menu:hover {
                    border-radius: 16px;
                    backdrop-filter: blur(10px);
                    box-shadow: 1px 1px 5px 0px rgba(255, 255, 255, 0.5);
                    border: 2px solid #e51d1a;
                }
            }
            .home-main-logout {
                width: 13%;
                margin: 70px auto;
                .logout-btn {
                    height: 50px;
                    border-radius: 6px;
                    border-color: rgba(229, 29, 26, 1);
                }
            }
        }
    }
</style>
