<template>
    <div class="activity">
        <div class="activity-main">
            <div class="activity-title">
                <div class="text">活动记录</div>
            </div>
            <div class="activity-content">
                <div class="activity-content-left">
                    <div class="record-title">
                        {{ activityList.name }}
                    </div>
                    <p v-if="activityList">
                        时间: {{ useLocalTime(activityList.createAt) }} -
                        {{ useLocalTime(activityList.end) }}
                    </p>
                    <div
                    >应到/实到人数：{{ detailsData.list.length }}/<span>{{
                            detailsData.actual.length
                        }}</span>
                        人</div
                    >
                    <div class="sign-all">
                        <div class="sign">签到人数:</div>
                        <div class="people" v-if="detailsData.actual">
                            <span v-for="(item, index) in detailsData.actual" :key="index">
                                {{ item.memberName }}
                                {{ index === detailsData.actual.length - 1 ? '' : ',' }}
                            </span>
                        </div>
                        <div v-else> 暂无 </div>
                    </div>
                    <div class="active-content">
                        <div>活动内容:</div>
                        <div>{{ activityList.introduce }}</div>
                    </div>
                </div>
                <div class="activity-content-right" v-if="activeShow">
                    <details-display
                            :recordNotes="0"
                            :recordImg="detailsData.userScenes"
                            :recordTitle="title"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {useLocalTime} from '@/utils/hook';
    import { getActivitiesRecords } from '@/api/activity';
    export default {
        data() {
            return {
                activeShow: false,
                activityList: {},
                detailsData: {
                    list: [],
                    notes: [],
                    scenes: [],
                    actual: [],
                },
                title: '学习笔记',
                titles: '现场照片',
                useLocalTime: useLocalTime,
            }
        },
        created() {
            this.activityList = JSON.parse(this.$route.query.value);
            this.activeList();
        },
        methods: {
            activeList() {
                getActivitiesRecords(this.activityList.id).then((res) => {
                    this.detailsData.list = res.data;
                    this.detailsData.actual = this.detailsData.list.filter((res) => res.signed);
                    this.detailsData.userScenes = this.detailsData.list.filter((res) => res.scenes);
                    this.activeShow = true;
                });
            },
        },
        mounted() {}
    }
</script>

<style scoped lang="less">
    /*.activity {*/
    /*    height: 100%;*/
    /*    overflow: hidden;*/
    /*}*/
    .activity-main {
        padding: 24px;
        .activity-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.4);
            }
        }
        .activity-content {
            display: flex;
            height: 90%;
            justify-content: space-between;
            margin-top: 1%;
            .activity-content-left {
                width: 39%;
                height: 95%;
                min-height: 500px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
                padding: 15px;
                background-color: #ffffff;
                * {
                    margin-top: 8px;
                    font-size: 16px;
                }
                .record-title {
                    font-size: 24px;
                    .span-tag {
                        background: #fbab17;
                        border-radius: 20px 0px 20px 0px;
                        color: white;
                        padding: 8px;
                        font-size: 18px;
                    }
                }
                .sign {
                    margin-top: 20px;
                    float: left;
                }
                .people {
                    color: black;
                    float: left;
                    font-weight: bold;
                    margin-top: 20px;
                }
                .active-content {
                    border: 1px dashed rgba(0, 0, 0, 0.2);
                    div {
                        font-weight: bold;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.5);
                        padding-left: 8px;
                    }
                    ul {
                        list-style: none;
                        padding-left: 10px;
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
            }
            .activity-content-right {
                width: 60%;
                height: 95%;
                min-height: 500px;
                background-color: #ffffff;
                .record-item {
                    color: white;
                    padding: 15px 20px;
                    text-align: left;
                    .record-item-title {
                        margin-bottom: 20px;
                    }
                    .record-item-tag {
                        span {
                            background: rgba(234, 234, 234, 0.6);
                            border-radius: 4px;
                            padding: 10px;
                            color: rgba(255, 255, 255, 0.8);
                            margin-right: 12px;
                            cursor: pointer;
                        }
                        .active {
                            background-color: rgba(229, 29, 26, 1);
                        }
                    }
                    .record-item-img {
                        overflow: hidden;
                        height: 170px;
                    }
                }
            }
        }
    }
    .sign-all::after {
        content: '';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .layout .layout-main {
        background: rgba(25, 26, 31, 0.75);
    }
</style>
