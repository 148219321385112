<template>
    <div class="branch">
        <div class="branch-main">
            <div class="branch-title">
                <div class="text">风采详情</div>
            </div>
            <div class="branch-content">
                <div class="branch-content-left">
                    <div class="record-title"> {{ branchValue.name }} </div>
                    <p>组织机构: {{branchValue.organizationName}}</p>
                    <p>创建人：{{ branchValue.creator }}</p>
                    <p>创建时间： {{ useLocalTime(branchValue.createAt) }}</p>
                    <div class="active-content">
                        <div>风采简介</div>
                        <p>{{ branchValue.introduce }}</p>
                    </div>
                </div>
                <div class="branch-content-right" v-if="activeShow">
                    <details-display
                            :recordNotes="2"
                            :recordImg="branchDetail.data"
                            :recordTitle="title"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {useLocalTime} from '@/utils/hook';
    export default {
        data() {
            return {
                branchDetail: {
                    data: [],
                },
                branchValue: {},
                detailsData: {
                    list: [],
                    notes: [],
                    scenes: [],
                    actual: [],
                },
                useLocalTime: useLocalTime,
                activeShow: false,
                title: '风采图片'
            }
        },
        created() {
            this.branchValue = JSON.parse(this.$route.query.value);
            console.log(this.branchValue);
            this.branchDetail.data.push(this.branchValue);
            this.activeShow = true;
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    .branch-main {
        padding: 24px;
        .branch-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.4);
            }
        }
        .branch-content {
            display: flex;
            height: 90%;
            justify-content: space-between;
            margin-top: 1%;
            .branch-content-left {
                width: 39%;
                height: 95%;
                min-height: 500px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
                padding: 15px;
                background-color: #ffffff;
                * {
                    margin-top: 8px;
                    font-size: 16px;
                }
                .record-title {
                    font-size: 24px;
                    .span-tag {
                        background: #fbab17;
                        border-radius: 20px 0px 20px 0px;
                        color: white;
                        padding: 8px;
                        font-size: 18px;
                    }
                }
                .sign {
                    margin-top: 20px;
                }
                .people {
                    color: black;
                    font-weight: bold;
                }
                .active-content {
                    border: 1px dashed rgba(0, 0, 0, 0.2);
                    div {
                        font-weight: bold;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.5);
                        padding-left: 8px;
                    }
                    p {
                        padding: 10px;
                    }
                }
            }
            .branch-content-right {
                width: 60%;
                height: 95%;
                min-height: 500px;
                background-color: #ffffff;
                .record-item {
                    color: white;
                    padding: 15px 20px;
                    text-align: left;
                    .record-item-title {
                        margin-bottom: 20px;
                    }
                    .record-item-tag {
                        span {
                            background: rgba(234, 234, 234, 0.6);
                            border-radius: 4px;
                            padding: 10px;
                            color: rgba(255, 255, 255, 0.8);
                            margin-right: 12px;
                            cursor: pointer;
                        }
                        .active {
                            background-color: rgba(229, 29, 26, 1);
                        }
                    }
                    .record-item-img {
                        overflow: hidden;
                        height: 170px;
                    }
                }
            }
        }
    }
    .layout .layout-main {
        background: rgba(25, 26, 31, 0.75);
    }
</style>
