
export function useTime(time) {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = Math.floor((time % 3600) - minutes * 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 && seconds >= 0 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
}

export function useLocalTime(nS) {
    if (!nS) return '';
    const date = new Date(parseInt(nS));
    const y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? '0' + MM : MM;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    let h = date.getHours();
    h = h < 10 ? '0' + h : h;
    let m = date.getMinutes();
    m = m < 10 ? '0' + m : m;
    let s = date.getSeconds();
    s = s < 10 ? '0' + s : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export function useGenerateData(data, key) {
    for (let i = 0; i < data.length; i++) {
        const keys = key + '_' + i;
        data[i].title = data[i].name;
        data[i].slots = { icon: 'smile' };
        data[i].key = keys;
        data[i].value = data[i].id;
        if (data[i].children) {
            data[i].children = useGenerateData(data[i].children, keys);
        }
    }
    return data;
}
