<template>
    <div class="layout">
        <a-layout class="layout-main" style="display: flex">
            <a-layout-sider :defaultCollapsed="true" class="layout-left">
                <div class="layout-left-icon" @click="returnGo">
                    <a-icon type="left" class="icon left" />
                </div>
                <div class="layout-left-home" @click="goHome">
                    <div class="layout-left-home-main">
                        <a-icon type="home" class="icon" />
                        <div class="text">首页</div>
                    </div>
                </div>
            </a-layout-sider>
            <a-layout class="layout-right" style="width: 100%">
                <router-view />
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
    import { LeftOutlined, HomeOutlined, SmileOutlined } from '@ant-design/icons-vue';
    export default {
        name: "layout",
        data () {
            return {}
        },
        created() {
        },
        methods: {
            returnGo() {
                this.$router.go(-1);
            },
            goHome() {
                this.$router.push('/home');
            },
        }
    }
</script>

<style lang="less">
    .layout {
        width: 100vw;
        height: 100vh;
        background: url('../assets/bg.png') no-repeat;
        background-size: 100%;
        .layout-main {
            width: 100vw;
            height: 100vh;
            background: rgba(242, 234, 234, 0.5);
            backdrop-filter: blur(10px);
        }
    }
    .layout-left {
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
        box-shadow: -1px 0px 2px 0px rgba(255, 255, 255, 0.2) inset;
        backdrop-filter: blur(10px);
        .layout-left-icon {
            width: 100%;
            height: 33%;
            float: left;
            cursor: pointer;
            .icons {
                cursor: pointer;
                margin-top: 35px;
            }
        }
        .layout-left-home {
            width: 100%;
            height: 28%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            .layout-left-home-main {
                cursor: pointer;
                .text {
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.4);
                    font-weight: bold;
                }
            }
        }
        .layout-left-user {
            width: 100%;
            height: 28%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .layout-left-user-main {
                cursor: pointer;
                .text {
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.4);
                    font-weight: bold;
                }
            }
        }
    }
    .layout-right {
        background: rgba(242, 234, 234, 0.1);
        backdrop-filter: blur(10px);
    }
    .icon {
        width: 30px;
        height: 30px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .left {
        margin-top: 30px;
    }
</style>
