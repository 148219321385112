import request from '@/utils/request';

export async function getCoursesPage(params) {
    return request({
        url: '/courses/screen-page',
        method: 'GET',
        params: params,
    });
}

export async function getCoursesDetail(id) {
    return request({
        url: '/courses/' + id,
        method: 'GET',
    });
}

export async function postFinish() {
    return request({
        url: '/course-studying/finish',
        method: 'POST',
    });
}

export async function postProgress(params) {
    return request({
        url: '/course-studying/progress',
        method: 'POST',
        data: params,
    });
}

export async function getCourseScreen(params)  {
    return request({
        url: '/course-records/screen-page',
        method: 'GET',
        params: { courseId: params },
    });
}

export async function getCategories()  {
    return request({
        url: '/course-categories',
        method: 'GET',
    });
}

export async function getScreenSign(id)  {
    return request({
        url: '/courses/' + id + '/screen-sign-info',
        method: 'GET',
    });
}

export async function getCheckStart(id)  {
    return request({
        url: '/course-studying/' + id + '/check-start',
        method: 'GET',
    });
}
