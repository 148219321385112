<template>
    <div class="login-box">
        <div class="login-mian">
            <div class="login-logo">
                <h1>智慧党建云课堂</h1>
            </div>
            <a-tabs v-model="activeKey">
                <a-tab-pane key="1" tab="忘记密码" force-render>
                    <a-form
                            style="margin-top: 30px"
                            name="custom-validation"
                            ref="formRef"
                            layout="horizontal"
                            :form="form"
                            validateTrigger="change"
                    >
                        <a-form-item has-feedback class="formItem" name="account">
                            <a-input
                                    autocomplete="off"
                                    placeholder="手机号"
                                    v-decorator="[
                                      'account',{ rules: [{ required: true, message: '请输入手机号' }] },
                                    ]"
                            >
                                <template #prefix><a-icon type="user" /></template>
                            </a-input>
                        </a-form-item>
                        <a-form-item class="formItem" name="code">
                            <a-input-search
                                    placeholder="验证码"
                                    autocomplete="off"
                                    :enter-button="Search"
                                    v-decorator="[
                                      'code',
                                      { rules: [{ required: true, message: '请输入验证码' }] },
                                    ]"
                                    @search="onSearch"
                            />
                        </a-form-item>
                        <a-form-item has-feedback class="formItem">
                            <a-input
                                    autocomplete="off"
                                    size="large"
                                    type="password"
                                    placeholder="新密码"
                                    v-decorator="[
                                      'password',
                                      { rules: [{ required: true, message: '请输入新密码' }] },
                                    ]"
                            >
                                <template #prefix><a-icon type="lock" /></template>
                            </a-input>
                        </a-form-item>
                        <a-form-item class="formItem submit">
                            <a-spin :spinning="spinning">
                                <a-button type="primary" block html-type="submit" @click="onSubmit">
                                    修改密码
                                </a-button>
                            </a-spin>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import { forgetCode, submitCode, forgetPassword } from '@/api/index';
    import { setToken } from '@/utils/localToken';
    import QRCode from 'qrcode';
    import md5 from 'md5';
    export default {
        name: "login",
        data () {
            return {
                activeKey: '1',
                formsState: {
                    account: '',
                    password: '',
                    code: '',
                },
                spinning: false,
                timer: null,
                signUrl: '',
                Search: '获取验证码',
                codeNum: 60,
                form: this.$form.createForm(this, { name: 'forget_rules' }),
            }
        },
        created() {
        },
        methods: {
            onSubmit() {
                this.form.validateFields((res, values) => {
                    if (!res) {
                        let cachePassword = values.password;
                        values.password = md5(values.password + 'spb!@#$%^&*');
                        submitCode(values).then((data) => {
                            if (data.code) {
                                forgetPassword(values).then(temp => {
                                    if (temp.code) {
                                        this.$message.success('密码修改成功');
                                        this.$router.push('/login');
                                    } else {
                                        values.password = cachePassword;
                                        this.$message.error('密码修改失败');
                                    }
                                });
                            } else {
                                values.password = cachePassword;
                                this.$message.error('验证码无效或已过期');
                            }
                        })
                    }
                });
            },
            onSearch() {
                this.form.validateFields((res, values) => {
                    let photoVerification = /^1[3456789]\d{9}$/;
                    if(!photoVerification.test(values.account)) return this.$message.error('填写正确的手机号');
                    if (this.codeNum < 60) return;
                    forgetCode(values.account).then((res) => {
                        this.$message.success('验证码发送成功');
                        if (res.code === 1) {
                            let t = setInterval(() => {
                                if (this.codeNum === 0) {
                                    this.Search = '重新发送验证码';
                                    clearInterval(t);
                                    this.codeNum = 60;
                                    return;
                                }
                                this.codeNum --;
                                this.Search = this.codeNum.toString();
                            }, 1000);
                        } else {
                            this.$message.error('验证码发送失败');
                        }
                    });
                });
            }
        },
        mounted() {
            // document.body.clientWidth
            this.$store.commit('getWidth', document.body.clientWidth);
        }
    }
</script>

<style lang="less">
    .login-box {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('../../assets/bg.png') no-repeat;
        background-size: 100%;
    }
    .login-mian {
        width: 30%;
        margin: 8% auto;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);
        border: none;
        .qr-code {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .login-logo h1 {
        margin-top: 25px;
        color: #e51d1a;
        font-weight: 600;
        font-size: 33px;
        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
    .ant-col.ant-form-item-control-wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .formItem {
        text-align: left;
        width: 80%;
        margin: 10px auto;
    }
    .formItem.submit {
        margin: 35px auto;
    }
    .ant-form-extra {
        display: none;
        color: red;
    }
</style>
