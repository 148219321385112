const settings = {
    siteTitle: 'ADMIN-ANTD-VUE',
    topNavEnable: true,
    headFixed: true,
    siteTokenKey: 'admin_antd_vue_token',
    ajaxHeadersTokenKey: 'Authorization',
    ajaxResponseNoVerifyUrl: [
        '/user/login', // 用户登录
        '/user/info', // 获取用户信息
    ],
    iconfontUrl: [],
};

export default settings;
