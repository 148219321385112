// @ts-ignore
import request from '@/utils/request';

export async function getActivities(params) {
    return request({
        url: '/activities/screen-page',
        method: 'GET',
        params: params,
    });
}

export async function getActivitiesRecords(params) {
    return request({
        url: '/activity-records/screen-page',
        method: 'GET',
        params: { activityId: params },
    });
}

