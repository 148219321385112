<template>
    <div class="login-box">
        <div class="login-mian">
            <div class="login-logo">
                <h1>智慧党建云课堂</h1>
            </div>
            <a-tabs v-model="activeKey" @change="onChange">
                <a-tab-pane key="1" tab="小程序扫码登录" style="margin-bottom: 16px">
                    <div class="qr-code">
                        <img v-if="signUrl" :src="signUrl" alt="" />
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="账号密码" force-render>
                    <a-form
                            style="margin-top: 30px"
                            name="custom-validation"
                            ref="formRef"
                            layout="horizontal"
                            :form="form"
                            validateTrigger="change"
                    >
                        <a-form-item has-feedback class="formItem" name="account">
                            <a-input
                                    autocomplete="off"
                                    placeholder="账号"
                                    v-decorator="[
                                      'account',{ rules: [{ required: true, message: '请输入账号' }] },
                                    ]"
                            >
                                <template #prefix><a-icon type="user" /></template>
                            </a-input>
                        </a-form-item>
                        <a-form-item has-feedback class="formItem" name="password">
                            <a-input
                                    autocomplete="off"
                                    size="large"
                                    type="password"
                                    placeholder="密码"
                                    v-decorator="[
                                      'password',
                                      { rules: [{ required: true, message: '请输入密码' }] },
                                    ]"
                            >
                                <template #prefix><a-icon type="lock" /></template>
                            </a-input>
                        </a-form-item>
                        <a-form-item class="formItem submit">
                            <a-spin :spinning="spinning">
                                <a-button type="primary" block html-type="submit" @click="onSubmit">
                                    登录
                                </a-button>
                            </a-spin>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
            <div class="edit-password" @click="forgetPassword">
                <span>忘记密码</span>
            </div>
        </div>
        <div>
            <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备17013099号-9</a>
            <a href=" " target="_blank"> 快媒数字 版权所有</a>
        </div>
    </div>
</template>

<script>
    import { Login, getInfo, getQrcode, postQrcodeLogin } from '@/api/index';
    import { setToken } from '@/utils/localToken';
    import QRCode from 'qrcode';
    import md5 from 'md5';
    export default {
        name: "login",
        data () {
            return {
                activeKey: '2',
                formsState: {
                    account: '',
                    password: '',
                },
                spinning: false,
                timer: null,
                signUrl: '',
                form: this.$form.createForm(this, { name: 'login_rules' }),
            }
        },
        created() {
        },
        methods: {
            onSubmit() {
                this.form.validateFields((res, values) => {
                    if (!res) {
                        values.password = md5(values.password + 'spb!@#$%^&*');
                        Login(values).then((res) => {
                            if (res.code === 1) {
                                getInfo().then((data) => {
                                    if (data.code === 1) {
                                        this.$store.commit('getInfoAllList', data.data);
                                        localStorage.setItem('infoAllList', JSON.stringify(data.data));
                                    }
                                });
                                clearInterval(this.timer);
                                this.$message.success('登录成功');
                                setToken(res.data);
                                this.$router.push('/home');
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }
                });
            },
            onChange(activeKey) {
                if (activeKey === '1') {
                    this.qrCodeLogin();
                } else {
                    clearInterval(this.timer);
                }
            },
            qrCodeLogin() {
                let userData = {};
                getQrcode().then((res) => {
                    if (res.code === 1) {
                        userData.data = res.data;
                        userData.type = 0;
                        QRCode.toDataURL(JSON.stringify(userData))
                            .then((url) => {
                                this.signUrl = url;
                            })
                            .catch((err) => {});
                        this.timer = setInterval(() => {
                            postQrcodeLogin(res.data).then((data) => {
                                if (data.code === 1) {
                                    if (data.data) {
                                        getInfo().then((data) => {
                                            if (data.code === 1) {
                                                this.$store.commit('getInfoAllList', data.data);
                                                localStorage.setItem('infoAllList', JSON.stringify(data.data));
                                            }
                                        });
                                        clearInterval(this.timer);
                                        this.$message.success('登录成功');
                                        setToken(data.data);
                                        this.$router.push('/home');
                                    }
                                } else {
                                    clearInterval(this.timer);
                                    this.$message.error(data.msg + '请刷新重试');
                                }
                            });
                        }, 5000);
                    }
                });
            },
            forgetPassword() {
                this.$router.push('/forget');
            }
        },
        mounted() {
            // document.body.clientWidth
            this.$store.commit('getWidth', document.body.clientWidth);
        }
    }
</script>

<style lang="less">
    .login-box {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('../../assets/bg.png') no-repeat;
        background-size: 100%;
    }
    .login-mian {
        width: 30%;
        margin: 8% auto;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);
        border: none;
        .qr-code {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .login-logo h1 {
        margin-top: 25px;
        color: #e51d1a;
        font-weight: 600;
        font-size: 33px;
        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
    .ant-col.ant-form-item-control-wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .formItem {
        text-align: left;
        width: 80%;
        margin: 10px auto;
    }
    .formItem.submit {
        margin: 35px auto;
    }
    .ant-form-extra {
        display: none;
        color: red;
    }
    .edit-password {
        text-align: left;
        margin: 0 auto;
        padding: 10px 0;
        border-top: 1px solid rgb(232, 232, 232);
        span {
            margin-left: 10%;
        }
        span:hover {
            cursor: pointer;
            color: #E51D1A;
            text-decoration: underline;
        }
    }
</style>
