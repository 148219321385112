<template>
    <div class="branch">
        <div class="branch-main">
            <div class="branch-title">
                <div class="text">支部风采</div>
                <div class="branch-tag" v-if="treeData.length">
                    <a-tree-select
                            v-model="paramsList.orgId"
                            style="width: 155px"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="treeData"
                            placeholder="组织机构"
                            tree-default-expand-all
                            @change="onTreeChange"
                    />
                </div>
            </div>
            <a-spin :spinning="spinning">
                <div class="branch-content">
                    <div class="branch-content-main">
                        <div v-for="(item, index) in branchList.data" :key="index" @click="routerLink(item)">
                            <div class="branch-content-item">
                                <img :src="cdnImgUrl + item.paths[0]" alt="" />
                                <div class="item-text">
                                    <div class="title">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a-pagination
                            style="margin-top: 20px"
                            v-model="currentValue"
                            :page-size="paramsList.size"
                            :page-size-options="pagination.pageSizeOptions"
                            :total="pagination.total"
                            show-size-changer
                            show-quick-jumper
                            @change="onChange"
                            @showSizeChange="onShowSizeChange"
                            :show-total="(total) => `共 ${total} 条数据`"
                    />
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import { useTime, useGenerateData } from '@/utils/hook';
    import { getStyle } from '@/api/branch';
    export default {
        data() {
            return {
                spinning: false,
                branchList: {
                    data: []
                },
                tagActive: 0,
                activeShow: 0,
                paramsList : {
                    orgId: undefined,
                    page: 0,
                    size: 10,
                    sort: '',
                    name: '',
                    status: 0,
                },
                currentValue: 1,
                pagination: {
                    pageSizeOptions: ['10', '20', '30'],
                    total: 0,
                },
                infoAllList: JSON.parse(localStorage.getItem('infoAllList')),
                cdnImgUrl: 'https://cdn-img.q-media.org.cn/',
                imgUrl: ['jpg', 'png', 'jpeg', 'bmp', 'wmf', 'mpeg'],
                useTime: useTime,
                useGenerateData: useGenerateData,
                treeData: [],
                value: ''
            }
        },
        created() {
            this.getBranch();
            this.treeData = this.useGenerateData([this.infoAllList.org], '0');
        },
        methods: {
            getBranch() {
                this.spinning = true;
                getStyle(this.paramsList).then((res) => {
                    this.branchList.data = res.data.list;
                    this.pagination.total = res.data.total;
                    this.generateData(this.treeData, this.branchList.data);
                    this.spinning = false;
                });
            },
            basculerActive(num) {
                this.paramsList.status = num;
                this.activeShow = num;
                this.getBranch();
            },
            onSearch() {
                this.getBranch();
            },
            onChange(page, pageSize) {
                this.paramsList.page = page - 1;
                this.getBranch();
            },
            onShowSizeChange(current, pageSize) {
                this.paramsList.size = pageSize;
                this.paramsList.page = 0;
                this.getBranch();
            },
            routerLink(item) {
                this.$router.push({ path: '/branch/details', query: { value: JSON.stringify(item) } });
            },
            onTreeChange(value) {
                this.paramsList.orgId = value;
                this.getBranch();
            },
            generateData(data, list) {
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < list.length; j++) {
                        if (data[i].id === list[j].orgId) {
                            list[j]['organizationName'] = data[i].name;
                        } else {
                            if (data[i].children) {
                                this.generateData(data[i].children, list);
                            }
                        }
                    }
                }
            },
        },
        mounted() {}
    }
</script>

<style scoped lang="less">
    .branch-main {
        padding: 24px;
        .branch-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: black;
            }
        }
        .branch-status {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            .branch-status-left {
                display: flex;
                .branch-status-item {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 25px;
                    color: rgba(0, 0, 0, 0.6);
                    margin-left: 18px;
                    cursor: pointer;
                    &:hover {
                        background: #e51d1a;
                        color: white;
                    }
                }
                .active {
                    background: #e51d1a;
                    color: white;
                }
            }
            .branch-status-right {
                width: 22%;
                margin-right: 5%;
            }
        }
        .branch-content-main {
            margin-top: 25px;
            margin-bottom: 25px;
            margin-left: 1%;
            .branch-content-item {
                width: 19%;
                cursor: pointer;
                float: left;
                margin-right: 1%;
                img {
                    width: 100%;
                    height: 140px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .item-text {
                    background-color: white;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    .title {
                        height: 67px;
                        text-align: left;
                        font-size: 18px;
                        padding-top: 8px;
                        padding-left: 8px;
                        padding-bottom: 3px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: black;
                    }
                    .progress {
                        width: 94%;
                        margin-bottom: 8px;
                    }
                }
            }
            &::after {
                content: '';
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
        }
    }
    .branch-tag {
        align-items: center;
        display: flex;
        margin-left: 30px;
    }
</style>
