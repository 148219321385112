import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '../router';
import settings from './settings';
import { getToken, removeToken, setToken } from './localToken';

const customCodeMessage = {
    10002: '当前用户登入信息已失效，请重新登入再操作', // 未登陆
};

const serverCodeMessage = {
    200: '服务器成功返回请求的数据',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: '服务器发生错误，请检查服务器(Internal Server Error)',
    502: '网关错误(Bad Gateway)',
    503: '服务不可用，服务器暂时过载或维护(Service Unavailable)',
    504: '网关超时(Gateway Timeout)',
};

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
    const { response, message } = error;
    if (message === 'CustomError') {
        // 自定义错误
        const { config, data } = response;
        const { url, baseURL } = config;
        const { code, msg } = data;
        const reqUrl = url.split('?')[0].replace(baseURL, '');
        const noVerifyBool = settings.ajaxResponseNoVerifyUrl.includes(reqUrl);
        if (!noVerifyBool) {
            this.$notification.error({
                message: `提示`,
                description: customCodeMessage[code] || msg || 'Error',
            });

            if (code === 2) {
                localStorage.removeItem('infoAllList');
                removeToken();
                this.$router.replace('/login');
            }
        }
    } else if (message === 'CancelToken') {
        // 取消请求 Token
        // eslint-disable-next-line no-console
    } else if (response && response.status) {
        const errorText = serverCodeMessage[response.status] || response.statusText;
        const { status, request } = response;
        this.$notification.error({
            message: `请求错误 ${status}: ${request.responseURL}`,
            description: errorText,
        });
    } else if (!response) {
        this.$notification.error({
            description: '您的网络发生异常，无法连接服务器',
            message: '网络异常',
        });
    }

    return Promise.reject(error);
};

/**
 * 配置request请求时的默认参数
 */
const request = axios.create({
    baseURL: '/api', // url = api url + request url
    withCredentials: true, // 当跨域请求时发送cookie
    timeout: 0, // 请求超时时间,5000(单位毫秒) / 0 不做限制
});

/**
 * 请求前
 * 请求拦截器
 */
request.interceptors.request.use(
    async (config) => {
        // 如果设置了cType 说明是自定义 添加 Content-Type类型 为自定义post 做铺垫
        if (config['cType']) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
        }
        // 自定义添加token header
        const headerToken = await getToken();
        if (headerToken) {
            config.headers[settings.ajaxHeadersTokenKey] = 'Bearer ' + headerToken;
        }

        return config;
    }
    /* error=> {} */ // 已在 export default catch
);

/**
 * 请求后
 * 响应拦截器
 */
request.interceptors.response.use(
    async (response) => {
        const res = response.data;
        const { code, token } = res;

        // 自定义状态码验证
        if (code === 2) {
            localStorage.removeItem('infoAllList');
            removeToken();
            router.push('/login');
        }

        // 重置刷新token
        if (token) {
            setToken(token);
        }

        return response;
    }
    /* error => {} */ // 已在 export default catch
);
// 导出
export default function (config) {
    return request(config)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
}
