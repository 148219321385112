<template>
    <div class="study">
        <div class="study-main">
            <div class="study-title">
                <div class="text">学习记录</div>
            </div>
            <div class="study-content">
                <div class="study-content-left">
                    <div class="record-title"> 课程：{{ studyDetail.name }} </div>
                    <p>学时：{{ useTime(studyDetail.duration) }}</p>
                    <div
                    >应到/实到人数：{{ studyData.list.length }}/<span>{{
                            studyData.actual.length
                        }}</span>
                        人</div
                    >
                    <div class="sign-all">
                        <div class="sign">签到人员:</div>
                        <div style="float: right; width: 85%; margin-top: 20px" v-if="studyData.actual">
                            <span v-for="(item, index) in studyData.actual" :key="index">
                                {{ item.memberName }}
                                {{ index === studyData.actual.length - 1 ? '' : ',' }}
                            </span>
                        </div>
                        <div v-else> 暂无 </div>
                    </div>
                    <div class="study-content">
                        <div>活动内容:</div>
                        <div>{{ studyDetail.introduce }}</div>
                    </div>
                </div>
                <div class="study-content-right" v-if="activeShow">
                    <details-display
                            :recordNotes="1"
                            :recordImg="studyData.notes"
                            :recordTitle="title"
                    />
                    <details-display
                            :recordNotes="0"
                            :recordImg="studyData.scenes"
                            :recordTitle="titles"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {useTime} from '@/utils/hook';
    import { getCourseScreen } from '@/api/study';
    export default {
        data() {
            return {
                studyDetail: {},
                studyData: {
                    list: [],
                    notes: [],
                    scenes: [],
                    actual: [],
                },
                title: '学习笔记',
                titles: '现场照片',
                useTime: useTime,
                activeShow: false,
            }
        },
        created() {
            this.studyDetail = JSON.parse(this.$route.query.value);
            this.getStudyRecord();
        },
        methods: {
            getStudyRecord() {
                getCourseScreen(this.studyDetail.id).then((res) => {
                    this.studyData.list = res.data;
                    this.studyData.notes = res.data.filter((res) => res.notes);
                    this.studyData.scenes = res.data.filter((res) => res.scenes);
                    this.studyData.actual = res.data.filter((res) => res.signed);
                    this.activeShow = true;
                });
            },
        },
        mounted() {}
    }
</script>

<style scoped lang="less">
    .study {
        height: 100%;
        /*overflow: hidden;*/
    }
    .study-main {
        padding: 24px;
        background-color: rgba(25, 26, 31, 0.75);
        backdrop-filter: blur(10px);
        .study-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.4);
            }
        }
        .study-content {
            display: flex;
            justify-content: space-between;
            margin-top: 1%;
            .study-content-left {
                width: 39%;
                min-height: 500px;
                background: #222328;
                color: rgba(255, 255, 255, 0.9);
                text-align: left;
                padding: 15px;
                * {
                    margin-top: 8px;
                }
                .record-title {
                    font-size: 24px;
                }
                .sign {
                    margin-top: 20px;
                    float: left;
                }
                .study-content {
                    border: 1px dashed rgba(255, 255, 255, 0.2);
                    display: block;
                    padding: 10px;
                }
            }
            .study-content-right {
                width: 60%;
                height: 95%;
                min-height: 500px;
                background: rgba(34, 35, 40, 1);
                .record-item {
                    color: white;
                    padding: 15px 20px;
                    text-align: left;
                    .record-item-title {
                        margin-bottom: 20px;
                    }
                    .record-item-tag {
                        span {
                            background: rgba(234, 234, 234, 0.6);
                            border-radius: 4px;
                            padding: 10px;
                            color: rgba(255, 255, 255, 0.8);
                            margin-right: 12px;
                            cursor: pointer;
                        }
                        .active {
                            background-color: rgba(229, 29, 26, 1);
                        }
                    }
                    .record-item-img {
                        overflow: hidden;
                        height: 170px;
                    }
                }
            }
        }
    }
    .layout .layout-main {
        background: rgba(25, 26, 31, 0.75);
    }
    .sign-all::after {
        content: '';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    @media screen and (min-width:900px) and (max-width:1279px) {
        .study-main {
            height: auto;
        }
    }
</style>
