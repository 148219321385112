<template>
    <div class="details">
        <div class="study-main">
            <div class="study-title" v-if="studyDetails.list.name">
                <div class="text">{{ studyDetails.list.name }}</div>
            </div>
            <div class="study-content">
                <div class="study-content-left" :key="keyNum">
                    <div v-if="resourcesLock">
                        <img :src="resourcesValue.url + resourcesValue.path" alt=""/>
                    </div>
                    <div class="show-full" v-if="!resourcesLock && !statusLock">
                        <video id="me1"
                               class="media lock"
                               width="620"
                               height="370"
                               :src="resourcesValue.videoUrl + resourcesValue.path + '?filekey=' + new Date().getTime()"
                               :poster="resourcesValue.videoUrl + resourcesValue.poster + '?filekey=' + new Date().getTime()"
                               webkit-playsinline="true"
                               playsinline="true"
                               x5-video-player-type="h5-page"
                               x5-video-player-fullscreen="true"
                        >
                        </video>
                        <a-icon class="icon" type="fullscreen" title="全屏" @click="fullScreen" />
                    </div>
                    <div v-if="!resourcesLock && statusLock">
                        <video
                                id="video"
                                class="media"
                                width="620"
                                height="370"
                                :src="resourcesValue.videoUrl + resourcesValue.path + '?filekey=' + new Date().getTime()"
                                :poster="resourcesValue.videoUrl + resourcesValue.poster + '?filekey=' + new Date().getTime()"
                                webkit-playsinline="true"
                                playsinline="true"
                                x5-video-player-type="h5-page"
                                x5-video-player-fullscreen="true"
                                controls
                        >
                        </video>
                    </div>
                    <div class="study-content-left-title">
                        <span class="title">{{ studyDetails.list.name }}</span>
                        <span>学时: {{ useTime(studyDetails.list.duration) }}</span>
                    </div>
                    <div class="study-content-left-text">
                        <p class="title">简介</p>
                        <p>{{ studyDetails.list.introduce }}</p>
                    </div>
                </div>
                <div class="study-content-right">
                    <div class="study-content-right-main">
                        <div class="study-content-right-title"> 学习列表</div>
                        <div>
                            <div v-if="!statusLock" class="study-content-right-item" :style="detailHeight">
                                <a-row>
                                    <div class="study-content-right-nav"
                                         :class="{ active: navLock - 1 === index }"
                                         v-for="(nav, index) in studyDetails.list.resources"
                                         :key="index"
                                         @click="resourcesNav(nav, index)">
                                        <a-col :span="2" class="format">{{ nav.mimeType }}</a-col>
                                        <a-col :offset="2" :span="12" class="text">{{
                                            nav.name
                                            }}
                                        </a-col>
                                        <a-col :span="4" class="time">
                                            {{ useTime(nav.duration) }}
                                        </a-col>
                                    </div>
                                </a-row>
                            </div>
                            <div v-else class="study-content-right-item" :style="detailHeight">
                                <a-row>
                                    <div class="study-content-right-nav"
                                         :class="{ active: navLock - 1 === index }"
                                         v-for="(nav, index) in studyDetails.list.resources"
                                         :key="index"
                                         @click="resourcesAll(nav, index)"
                                    >
                                        <a-col :span="2" class="format">{{ nav.mimeType }}</a-col>
                                        <a-col :offset="2" :span="12" class="text">{{
                                            nav.name
                                            }}
                                        </a-col>
                                        <a-col :span="4" class="time">
                                            {{ useTime(nav.duration) }}
                                        </a-col>
                                    </div>
                                </a-row>
                            </div>
                        </div>
                        <div class="study-content-right-tag">
                            <span class="sign" @click="signClick">签到</span>
                            <span v-if="studyStatus === '0'" class="curriculum" @click="startStudy">
                                开始学习
                            </span>
                            <span v-else class="curriculum" @click="completeStudy">完成课程</span>
                            <router-link
                                    class="record"
                                    :to="{
                                    path: '/study/record',
                                    query: { value: JSON.stringify(studyDetails.list) },
                                }"
                            >
                                <span>学习记录</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <a-modal
                    width="38%"
                    title="签到"
                    class="modalProps sign"
                    :visible="visible"
                    @cancel="previewCancel"
            >
                <div class="preview-main">
                    <p class="title">小程序扫一扫,开始签到</p>
                    <div id="code">
                        <img v-if="signUrl" :src="signUrl" alt=""/>
                    </div>
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
    import {
        getCoursesDetail,
        getScreenSign,
        postProgress,
        getCheckStart,
        postFinish,
    } from '@/api/study';
    import {useTime} from '@/utils/hook';
    import store from '../../store';
    import QRCode from 'qrcode';

    export default {
        data() {
            return {
                studyDetails: {
                    list: []
                },
                keyNum: 0,
                resourcesLock: false,
                resourcesValue: {
                    name: '',
                    duration: 0,
                    path: '',
                    mimeType: '',
                    id: '',
                    url: '',
                    videoUrl: '',
                },
                navLock: 0,
                statusLock: false,
                autoPlays: false,
                useTime: useTime,
                detailHeight: {
                    height: '380px'
                },
                studyId: '',
                studyStatus: '0',
                infoAllList: localStorage.getItem('infoAllList'),
                imgUrl: ['jpg', 'png', 'jpeg', 'bmp', 'wmf', 'mpeg'],
                t: null,
                visible: false,
                signUrl: '',
                cdnImgUrl: 'https://cdn-img.q-media.org.cn/',
                cdnVideoUrl: 'https://cdn-video.q-media.org.cn/',
                cctvCdnImgUrl: 'https://cdn-img.q-media.red/',
                cctvCdnVideoUrl: 'https://cdn-video.q-media.red/',
                signWidth: '30%'
            }
        },
        created() {
            this.studyId = this.$route.query.id;
            // this.studyStatus = this.$route.query.status;
            this.infoAllList = JSON.parse(this.infoAllList);
            if(this.$route.query.status === '2') {
                this.studyStatus = '2';
            }
            this.studyDetailsList();
            if (this.$store.state.inWidth && this.$store.state.inWidth < 1280) {
                this.detailHeight.height = '320px';
                this.signWidth = '40%'
            }
        },
        methods: {
            studyDetailsList() {
                getCoursesDetail(this.studyId).then((res) => {
                    this.studyDetails.list = res.data;
                    if (this.studyDetails.list.resources[0].id.type === 0) {
                        this.resourcesValue.url = this.infoAllList.cctvCdnImgUrl;
                        this.resourcesValue.videoUrl = this.infoAllList.cctvCdnVideoUrl;
                    } else {
                        this.resourcesValue.url = this.infoAllList.cdnImgUrl;
                        this.resourcesValue.videoUrl = this.infoAllList.cdnVideoUrl;
                    }
                    this.studyDetails.list.resources.forEach((item) => {
                        item.mimeType = item.mimeType.split('/')[1];
                    });
                    this.resourcesValue.name = this.studyDetails.list.resources[0].name;
                    this.resourcesValue.duration = this.studyDetails.list.resources[0].duration;
                    this.resourcesValue.path = this.studyDetails.list.resources[0].path;
                    this.resourcesValue.poster = this.resourcesValue.path + '.jpg';
                    this.resourcesValue.mimeType = this.studyDetails.list.resources[0].mimeType;
                    this.resourcesValue.id = this.studyDetails.list.resources[0].id;
                    if (this.imgUrl.includes(this.resourcesValue.mimeType)) {
                        this.resourcesLock = true;
                    } else {
                        this.resourcesLock = false;
                    }
                    if (this.studyStatus === '2') {
                        this.statusLock = true;
                        this.$nextTick(() => {
                            if (!this.resourcesLock) {
                                this.navLock = this.navLock+ 1;
                                this.endPlay();
                            } else {
                                this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                            }
                        });
                        // this.autoSwitch(this说.studyDetails.list.resources[this.navLock]);
                    } else {
                        this.$nextTick(() => {
                            if (!this.resourcesLock) {
                                this.autoBtnPlay();
                            }
                        });
                    }
                });
            },
            autoSwitch(value) {
                if (this.navLock > this.studyDetails.list.resources.length - 1) {
                    this.studyOver = true;
                    this.$message.success('课程学习完毕,点击完成课程吧');
                    return;
                }
                this.resourcesValue.name = value.name;
                this.resourcesValue.duration = value.duration;
                this.resourcesValue.path = value.path;
                this.resourcesValue.mimeType = value.mimeType;
                this.resourcesValue.id = value.id;
                this.resourcesValue.poster = this.resourcesValue.path + '.jpg';
                // 判断资源央视还是内部,判断资源类型
                if (value.id.type === 0) {
                    this.resourcesValue.url = this.infoAllList.cctvCdnImgUrl;
                    this.resourcesValue.videoUrl = this.infoAllList.cctvCdnImgUrl;
                } else {
                    this.resourcesValue.url = this.infoAllList.cdnImgUrl;
                    this.resourcesValue.videoUrl = this.infoAllList.cdnVideoUrl;
                }
                this.navLock = this.navLock + 1;
                this.keyNum = this.keyNum++;
                if (this.imgUrl.includes(this.resourcesValue.mimeType)) {
                    this.resourcesLock = true;
                    this.t = setTimeout(() => {
                        if (!this.statusLock) {
                            postProgress(value.id).then((res) => {
                                if (res.code !== 1 && this.$route.query.status !== '2') {
                                    this.$message.error('该课程学习失败');
                                }
                            });
                        }
                        return this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                    }, value.duration * 1000);
                } else {
                    this.resourcesLock = false;
                    this.$nextTick(() => {
                        let mediaLock = document.getElementById('me1');
                        let mediaVideo = document.getElementById('video');
                        if (mediaLock) {
                            mediaLock.autoplay = 'autoplay';
                        }
                        if (mediaVideo) {
                            mediaVideo.autoplay = 'autoplay';
                        }
                        if (!this.resourcesLock && !this.statusLock) {
                            this.autoBtnPlay();
                        }
                    });
                }
            },
            previewCancel() {
                this.visible = false;
            },
            resourcesNav(nav, index) {
                if (this.navLock > index) {
                    // 判断文件类型 做出时间推算
                    clearTimeout(this.t);
                    this.throttleList(index);
                } else {
                    return;
                }
            },
            throttleList(index) {
                this.navLock = index;
                this.autoSwitch(this.studyDetails.list.resources[index]);
            },
            resourcesAll(nav, index) {
                clearTimeout(this.t);
                this.throttleList(index);
            },
            signClick() {
                this.visible = true;
                this.useqrcode();
            },
            useqrcode() {
                let userData = {};
                getScreenSign(this.studyId).then((res) => {
                    userData.data = res.data;
                    userData.courseId = this.studyId;
                    userData.type = 1;
                    QRCode.toDataURL(JSON.stringify(userData))
                        .then((url) => {
                            this.signUrl = url;
                        })
                        .catch((err) => {
                        });
                });
                const generateQR = async (text) => {
                    try {
                        console.log(text);
                    } catch (err) {
                        console.error(err);
                    }
                };
            },
            startStudy() {
                getCheckStart(this.studyId).then((res) => {
                    if (res.code === 0) {
                        return this.$message.error('请先签到在开始学习');
                    } else {
                        this.studyStatus = '1';
                        this.typeLock = false;
                        this.autoPlays = true;
                        let mediaLock = document.getElementById('me1');
                        let mediaVideo = document.getElementById('video');
                        if (mediaLock) {
                            mediaLock.play();
                            mediaLock.autoplay = 'autoplay';
                        }
                        if (mediaVideo) {
                            mediaVideo.autoplay = 'autoplay';
                        }
                        this.$message.success('课程加载成功,请耐心学完课程');
                        this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                        // Android.onX5ButtonClicked();
                    }
                });
            },
            completeStudy() {
                postFinish().then((res) => {
                    if (res.code === 1) {
                        this.$message.success('课程学习完毕');
                        this.studyStatus = '2';
                        this.navLock = 0;
                        this.statusLock = true;
                        this.studyDetailsList();
                    } else {
                        this.$message.error('课程学习失败');
                    }
                });
            },
            full(ele) {
                if (ele.requestFullscreen) {
                    ele.requestFullscreen();
                } else if (ele.mozRequestFullScreen) {
                    ele.mozRequestFullScreen();
                } else if (ele.webkitRequestFullscreen) {
                    ele.webkitRequestFullscreen();
                } else if (ele.msRequestFullscreen) {
                    ele.msRequestFullscreen();
                }
            },
            fullScreen() {
                getCheckStart(this.studyId).then((res) => {
                    if (res.code === 0) {
                        return this.$message.error('请先签到在开始学习');
                    } else {
                        this.studyStatus = '1';
                        this.typeLock = false;
                        this.autoPlays = true;
                        let mediaLock = document.getElementById('me1');
                        let mediaVideo = document.getElementById('video');
                        if (mediaLock) {
                            mediaLock.autoplay = 'autoplay';
                            mediaLock.play();
                            this.full(mediaLock);
                        }
                        if (mediaVideo) {
                            mediaVideo.autoplay = 'autoplay';
                        }
                        // Android.onX5ButtonClicked();
                        this.$message.success('课程加载成功,请耐心学完课程');
                        this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                    }
                });
            },
            btnPlay() {
                let mediaLock = document.getElementById('video');
                mediaLock.play();
            },
            autoBtnPlay() {
                let mediaLock = document.getElementById('me1');
                mediaLock.addEventListener('click',  () => {
                    this.full(mediaLock);
                });
                mediaLock.addEventListener('ended', () => {
                    if (!this.statusLock) {
                        postProgress(this.resourcesValue.id).then((res) => {
                            if (res.code !== 1) {
                                this.$message.error('该课程学习失败');
                            }
                        });
                    }
                    this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                });
            },
            endPlay() {
                let mediaLockCon = document.getElementById('video');
                mediaLockCon.addEventListener('ended', () => {
                    this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
                });
            }
        },
        mounted() {
            window.onbeforeunload = function (e) {
                if (this.$router.currentRoute.path == '/study/details') {
                    e = e || window.event;
                    if (e) {
                        e.returnValue = '关闭提示';
                    }
                    return '关闭提示';
                } else {
                    window.onbeforeunload = null;
                }
            };
            if (document.body.clientWidth && document.body.clientWidth < 1280) {
                this.detailHeight.height = '320px';
            }
            // let mediaLock = document.getElementById('me1');
            // mediaLock.addEventListener('click',  () => {
            //     this.full(mediaLock);
            // });
            // mediaLock.addEventListener('ended', () => {
            //     if (!this.statusLock) {
            //         postProgress(this.resourcesValue.id).then((res) => {
            //             if (res.code !== 1) {
            //                 this.$message.error('该课程学习失败');
            //             }
            //         });
            //     }
            //     this.autoSwitch(this.studyDetails.list.resources[this.navLock]);
            // });
        }
    }
</script>

<style scoped lang="less">
    video.lock::-webkit-media-controls-enclosure {
        display: none;
    }
    #code {
        margin: 0 auto;

        img {
            display: block;
            width: 300px;
            height: 300px;
            margin: 0 auto;
        }
    }

    .study {
        height: 100vh;
    }

    .media {
        margin: 0 auto;
    }

    .preview-main {
        .title {
            font-size: 30px;
            text-align: center;
            margin: 0;
        }
    }

    .study-main {
        padding: 24px;
        background-color: rgba(25, 26, 31, 0.75);
        backdrop-filter: blur(10px);
        height: 100vh;
        overflow: auto;

        .study-title {
            display: flex;

            .text {
                font-size: 48px;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.4);
            }
        }

        .study-content {
            display: flex;
            justify-content: space-between;

            .study-content-left {
                width: 700px;
                height: 100%;

                img {
                    height: 450px;
                }

                .resources {
                    width: 450px;
                    display: block;
                }

                .show-full {
                    position: relative;

                    .show-full-btn {
                        position: absolute;
                        bottom: 5px;
                        right: 0;
                    }

                    .iconTag {
                        width: 30px;
                        height: 30px;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                .study-content-left-title {
                    display: flex;
                    justify-content: space-between;
                    line-height: 40px;
                    color: rgba(255, 255, 255, 0.9);

                    .title {
                        font-size: 20px;
                    }
                }

                .study-content-left-text {
                    text-align: left;
                    color: rgba(255, 255, 255, 0.8);

                    p {
                        font-size: 14px;
                    }

                    .title {
                        font-size: 15px;
                        margin-bottom: 0;
                        color: rgba(255, 255, 255, 0.4);
                    }
                }
            }

            .study-content-right {
                width: 45%;
                background: rgba(34, 35, 40, 1);
                position: relative;
                height: 488px;

                .study-content-right-main {
                    height: 100%;
                }

                .study-content-right-title {
                    font-weight: 600;
                    font-size: 20px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.8);
                    padding: 15px 15px 1px 15px;
                }

                .study-content-right-item {
                    overflow: auto;

                    .study-content-right-nav {
                        display: flex;
                        justify-content: center;
                        margin-top: 15px;
                        padding: 8px;
                        cursor: pointer;

                        &:hover {
                            background: rgba(229, 29, 26, 0.4);
                        }

                        .format {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            background-color: rgba(34, 35, 40, 1);
                            color: rgba(255, 255, 255, 0.6);
                            border: 1px solid #000000;
                            border-radius: 5px;
                        }

                        .text {
                            width: 65%;
                            line-height: 50px;
                            color: rgba(255, 255, 255, 0.8);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .time {
                            color: rgba(255, 255, 255, 0.4);
                            line-height: 45px;
                        }
                    }

                    .study-content-right-nav:last-of-type {
                        margin-bottom: 100px;
                    }

                    .study-content-right-nav.active {
                        background: rgba(229, 29, 26, 0.4);
                    }
                }

                .study-content-right-tag {
                    display: flex;
                    justify-content: space-evenly;
                    color: #ffffff;
                    cursor: pointer;
                    width: 100%;
                    position: absolute;
                    bottom: 10px;

                    .sign {
                        display: block;
                        width: 25%;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        background: #ffa500;
                        border-radius: 16px;
                    }

                    .curriculum {
                        display: block;
                        width: 25%;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        background: #e51d1a;
                        border-radius: 16px;
                    }

                    .record {
                        display: block;
                        width: 25%;
                        height: 60px;
                        color: white;
                        line-height: 60px;
                        text-align: center;
                        background: #0dc510;
                        border-radius: 16px;
                    }
                }
            }
        }
    }

    .layout .layout-main {
        background: rgba(25, 26, 31, 0.75);
    }

    .icon {
        color: #ffffff;
        position: absolute;
        bottom: 8px;
        right: 22px;
    }
    .icon svg {
        color: #ffffff;
        width: 30px;
        height: 30px;
    }
</style>
