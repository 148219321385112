<template>
    <div class="study">
        <div class="study-main">
            <div class="study-title">
                <div class="text">课程学习</div>
                <div class="study-tag">
                    <ul>
                        <li
                                v-for="(tag, index) in tagList.data"
                                :key="index"
                                :class="tagActive === index ? 'active' : ''"
                                @click="tagBtn(index, tag)"
                        >{{ tag.name }}
                        </li
                        >
                    </ul>
                </div>
            </div>
            <a-spin :spinning="spinning">
                <div class="study-status">
                    <div class="study-status-left">
                        <div
                                :class="{ active: activeShow === 0 }"
                                @click="basculerActive(0)"
                                class="study-status-item"
                        >未学习
                        </div
                        >
                        <div
                                :class="{ active: activeShow === 1 }"
                                @click="basculerActive(1)"
                                class="study-status-item"
                        >进行中
                        </div
                        >
                        <div
                                :class="{ active: activeShow === 2 }"
                                @click="basculerActive(2)"
                                class="study-status-item"
                        >已完成
                        </div
                        >
                    </div>
                    <div class="study-status-right">
                        <a-input-search
                                v-model="paramsList.name"
                                placeholder="课程名称"
                                style="height: 40px; width: 100%"
                                @search="onSearch"
                        />
                    </div>
                </div>
                <div class="study-content">
                    <div class="study-content-main" v-if="studyList.data.length">
                        <div v-for="item in studyList.data" v-bind:key="item.id" @click="routerLink(item)">
                                <div class="study-content-item">
                                    <div class="study-content-time">
                                        {{ useTime(item.duration) }}
                                    </div>
                                    <img :src="infoAllList.cdnImgUrl + item.coverPage" alt=""/>
                                    <div class="item-text">
                                        <div class="title">{{ item.name }}</div>
                                    </div>
                                </div>
                        </div>
                        <!--                        <a-empty v-if="studyList.data.length === 0" :image="simpleImage" />-->
                    </div>
                    <a-pagination
                            style="margin-top: 20px"
                            v-model="currentValue"
                            :page-size="paramsList.size"
                            :page-size-options="pagination.pageSizeOptions"
                            :total="pagination.total"
                            show-size-changer
                            show-quick-jumper
                            @change="onChange"
                            @showSizeChange="onShowSizeChange"
                            :show-total="(total) => `共 ${total} 条数据`"
                    />
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import { getCoursesPage, getCategories } from '@/api/study';
    import { useTime } from '@/utils/hook';
    export default {
        name: "study",
        data() {
            return {
                spinning: false,
                tagList: {
                    data: []
                },
                studyList: {
                    data: []
                },
                tagActive: 0,
                activeShow: 0,
                paramsList : {
                    categoryId: '',
                    page: 0,
                    size: 10,
                    sort: '',
                    name: '',
                    status: 0,
                },
                currentValue: 1,
                pagination: {
                    pageSizeOptions: ['10', '20', '30'],
                    total: 0,
                },
                infoAllList: localStorage.getItem('infoAllList'),
                cdnImgUrl: 'https://cdn-img.q-media.org.cn/',
                imgUrl: ['jpg', 'png', 'jpeg', 'bmp', 'wmf', 'mpeg'],
                useTime: useTime,
            }
        },
        beforeCreate() {
        },
        created() {
            this.infoAllList = JSON.parse(this.infoAllList);
            this.getStudyList();
            this.getTypeList();
        },
        methods: {
            getStudyList() {
                this.spinning = true;
                let that = this;
                getCoursesPage(this.paramsList).then((res) => {
                    that.studyList.data = res.data.list;
                    that.pagination.total = res.data.total;
                    this.spinning = false;
                });
            },
            getTypeList() {
                getCategories().then((res) => {
                    this.tagList.data = res.data;
                    this.tagList.data.unshift({
                        name: '全部',
                        id: '',
                    });
                });
            },
            tagBtn(index, value) {
                this.tagActive = index;
                this.paramsList.categoryId = value.id;
                this.getStudyList();
            },
            basculerActive(num) {
                this.paramsList.status = num;
                this.activeShow = num;
                this.getStudyList();
            },
            onSearch() {
                this.getStudyList();
            },
            onChange(page, pageSize) {
                this.paramsList.page = page - 1;
                this.getStudyList();
            },
            onShowSizeChange(current, pageSize) {
                this.paramsList.size = pageSize;
                this.paramsList.page = 0;
                this.getStudyList();
            },
            routerLink(item) {
                this.$router.push({ path: '/study/details', query: { id: item.id, status: this.activeShow}});
            }
        }
    }
</script>

<style scoped lang="less">
    .study-main {
        padding: 24px;
        .study-title {
            display: flex;
            .text {
                font-size: 48px;
                font-weight: bold;
                color: black;
            }
            ul {
                list-style: none;
                display: flex;
                li {
                    font-size: 20px;
                    line-height: 42px;
                    color: black;
                    padding: 8px 15px;
                    margin: 0 10px;
                    cursor: pointer;
                    &:hover {
                        background: #e51d1a;
                        color: white;
                        border-radius: 16px;
                    }
                }
                .active {
                    background: #e51d1a;
                    color: white;
                    border-radius: 16px;
                }
            }
        }
        .study-status {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            .study-status-left {
                display: flex;
                .study-status-item {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 25px;
                    color: rgba(0, 0, 0, 0.6);
                    margin-left: 18px;
                    cursor: pointer;
                    &:hover {
                        background: #e51d1a;
                        color: white;
                    }
                }
                .active {
                    background: #e51d1a;
                    color: white;
                }
            }
            .study-status-right {
                width: 22%;
                margin-right: 5%;
            }
        }
        .study-content-main {
            margin-top: 25px;
            margin-bottom: 5px;
            margin-left: 1%;
            .study-content-item {
                width: 19%;
                cursor: pointer;
                float: left;
                margin-right: 1%;
                margin-bottom: 14px;
                position: relative;
                .study-content-time {
                    position: absolute;
                    right: 0;
                    bottom: 67px;
                    padding: 6px;
                    background-color: rgba(0, 0, 0, 0.4);
                    color: white;
                }
                img {
                    width: 100%;
                    height: 140px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .item-text {
                    background-color: white;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    .title {
                        height: 67px;
                        text-align: left;
                        font-size: 18px;
                        padding-top: 8px;
                        padding-left: 8px;
                        padding-bottom: 3px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: black;
                    }
                    .progress {
                        width: 94%;
                        margin-bottom: 8px;
                    }
                }
            }
            &::after {
                content: '';
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
        }
    }
</style>
